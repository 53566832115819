import React, { useEffect, useRef } from "react";
import { Logo } from "../../components/Logo";
import "./index.module.scss";
import Navigation from "../../components/Navigation";
import Menu from "../../components/Menu";
import styles from "./index.module.scss";
import gsap from "gsap";
import useHeaderVisibility from "../../../customHooks/useHeaderVisibility";
import useMediaQuery from "../../../customHooks/useMediaQuery";

const NewHeader = ({ menu }) => {
  const headerRef = useRef(null);
  const headerVisible = useHeaderVisibility(600);
  const isMobile = useMediaQuery("(max-width:48em)");

  useEffect(() => {
    const header = headerRef.current;
    gsap.to(header, {
      y: headerVisible ? 0 : -100,
      z: isMobile ? 0 : 1,
      autoAlpha: headerVisible ? 1 : 0,
      duration: 0.5,
      overwrite: "all",
    });
  }, [headerVisible, isMobile]);

  return (
    <div className={styles.wrapper}>
      <header ref={headerRef} className={`${styles.topLine} row jsb`}>
        <Logo />
        <Navigation menu={menu} />
        <Menu />
      </header>
    </div>
  );
};

export default NewHeader;
