import { useLocation } from "react-router-dom";
import { FetchContext } from "../context/FetchContext";
import { useContext, useEffect, useState } from "react";

export function useIsNewPost() {
  const location = useLocation();
  const [isNewPost, setIsNewPost] = useState(null);
  const {
    store: {
      posts: {
        post: { data: post },
      },
    },
  } = useContext(FetchContext);

  useEffect(() => {
    setIsNewPost(
      post[0]?.slug === location.pathname.split("/")[2] &&
        post[0]?.slug !== undefined &&
        post[0]?.acf.isnewpost !== ""
    );
  }, [post, location.pathname]);
  return isNewPost;
}
