import classes from "./UniversityLink.module.scss";
import { ReactComponent as Icon } from "../../assets/img/WebinarIcon.svg";
export default function UniversityLink() {
  return (
    <a href="https://university.engenious.io/" className={classes.button}>
      <Icon />
      <span>University</span>
    </a>
  );
}
