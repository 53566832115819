import React from "react";
import styles from "./index.module.scss";

export const InputOutline = ({ id, name, label, value, type, errorValue, ...props }) => {
  return (
    <div>
      <span className={styles.label}>{label}</span>
      <input
        id={id}
        name={name}
        value={value}
        type={type}
        {...props}
        style={{
          border: errorValue ? "2px solid rgba(255, 61, 46, 0.2)" : null,
        }}
        className={styles.input}
      />
      {errorValue && <p className={styles.errorText}>{errorValue}</p>}
    </div>
  );
};
