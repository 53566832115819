import { useState } from "react";
import { dataCreatorHub } from "../../data/creatorHub";
import { InputOutline } from "../Inputs/InputOutline";
import { PhoneInputOutline } from "../Inputs/PhoneInputOutline";
import { TextareaOutline } from "../Inputs/TextAreaOutline";
import styles from "./index.module.scss";

export default function CreatorHubForm() {
  const [inputErrors, setInputErrors] = useState({
    name: false,
    lastName: false,
    email: false,
    phone: false,
    message: false,
  });

  const validateForm = (name, lastName, email, phone, message) => {
    let valid = true;
    const errors = {};
    // name
    if (name.trim() === '') {
      valid = false;
      errors.name = "First name can't be empty"
    } else if (!/^[a-zA-Z]*$/.test(name)) {
      valid = false;
      errors.name = 'First name is invalid'
    }
    // last name
    if (lastName.trim() === '') {
      valid = false;
      errors.lastName = "Last name can't be empty"
    } else if (!/^[a-zA-Z]*$/.test(lastName)) {
      valid = false;
      errors.lastName = 'Last name is invalid'
    }
    // email
    if (email.trim() === '') {
      valid = false;
      errors.email = "Email can't be empty"
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      valid = false;
      errors.email = 'Email is invalid'
    }
    // phone 
    if (!(phone.replace(/\D/g, '').length >= 10)) {
      valid = false;
      errors.phone = "Phone number is invalid";
    }
    // message
    if (message.trim() === '') {
      valid = false;
      errors.message = "Message can't be empty"
    } else if (message.length > 500) {
      valid = false;
      errors.message = "Message exceeds maximum character limit";
    }
    setInputErrors(errors);
    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const name = event.target.elements.inf_field_FirstName.value;
    const lastName = event.target.elements.inf_field_LastName.value;
    const email = event.target.elements.inf_field_Email.value;
    const phone = event.target.elements.inf_field_Phone1.value;
    const message = event.target.elements.inf_custom_Message.value;
    if (validateForm(name, lastName, email, phone, message)) {
      event.target.submit();
    }
  };
  return (
    <form
      accept-charset="UTF-8"
      action="https://jvz601.infusionsoft.com/app/form/process/fbae68579ac1dbe6f0428885be7259d1"
      class="infusion-form"
      id="inf_form_fbae68579ac1dbe6f0428885be7259d1"
      method="POST"
      onSubmit={handleSubmit}
    >
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <h3 className={styles.title}>{dataCreatorHub.modalWindowSection.formTitle}</h3>
        </div>

        <input name="inf_form_xid" type="hidden" value="fbae68579ac1dbe6f0428885be7259d1" />
        <input name="inf_form_name" type="hidden" value="Web Form submitted" />
        <input name="infusionsoft_version" type="hidden" value="1.70.0.661530" />

        <div className={styles.inputsWrapper}>
          <InputOutline
            id="inf_field_FirstName"
            name="inf_field_FirstName"
            type="text"
            label="First name*"
            placeholder="First name"
            errorValue={inputErrors.name}
          />
          <InputOutline
            id="inf_field_LastName"
            name="inf_field_LastName"
            label="Last name*"
            type="text"
            placeholder="Last name"
            errorValue={inputErrors.lastName}
          />
          <InputOutline
            id="inf_field_Email"
            name="inf_field_Email"
            label="Email*"
            type="text"
            placeholder="Email"
            errorValue={inputErrors.email}
          />
          <PhoneInputOutline
            id="inf_field_Phone1"
            name="inf_field_Phone1"
            label="Phone Number*"
            errorValue={inputErrors.phone}
          />
        </div>

        <div className={styles.tagsWrapper}>
          <span className={styles.tagsLabel}>{dataCreatorHub.modalWindowSection.formTagsLabel}</span>
          <div className={styles.tagsList}>
            {dataCreatorHub.modalWindowSection.formTags.map((item, index) => (
              <div key={item.id} className={styles.formTag}>
                <label htmlFor={item.id}>
                  <input type="checkbox" id={item.id} name={item.name} value={item.value} />
                  <span>{item.text}</span>
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.formMessageWrapper}>
          <TextareaOutline
            id="inf_custom_Message"
            name="inf_custom_Message"
            label={"Message*"}
            placeholder="Message (Anything else you would like to share with our representatives Eg: social media handles, website or youtube links.)"
            errorValue={inputErrors.message}
          />
        </div>

        <button className={styles.submitButton} id="recaptcha_fbae68579ac1dbe6f0428885be7259d1" type="submit">
          {dataCreatorHub.modalWindowSection.formSubmitButtonText}
        </button>

        <div className={styles.linksWrapper}>
          {/* <div>
            <span className={styles.linkLabel}>{dataCreatorHub.modalWindowSection.formPhoneLabel}</span>
            <a href={`tel:${dataCreatorHub.modalWindowSection.formPhone}`} className={styles.link}>
              {dataCreatorHub.modalWindowSection.formPhoneText}
            </a>
          </div> */}
          <div>
            <span className={styles.linkLabel}>{dataCreatorHub.modalWindowSection.formEmailLabel}</span>
            <a href={`mailto:${dataCreatorHub.modalWindowSection.formEmail}`} className={styles.link}>
              {dataCreatorHub.modalWindowSection.formEmail}
            </a>
          </div>
        </div>
        <p className={styles.footerDescription}>{dataCreatorHub.modalWindowSection.formFooterDescription}</p>
      </div>
    </form>
  );
}
