import { useState, useEffect } from "react";
import { dataCreatorHub } from "../../data/creatorHub";
import DarkButton from "../Buttons/DarkButton";
import FooterButton from "../Buttons/FooterButton";
import CreatorHubForm from "../Form";
import styles from "./index.module.scss";

export const ModalWindow = ({ text, isFooter = false, isHeader = false, isLanding }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const bodyComponent = document.getElementById("engenious");
    if (bodyComponent) {
      bodyComponent.style.overflow = isOpen ? "hidden" : "";
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className={styles.backgroundContainer}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <div className={styles.container} onClick={(e) => e.stopPropagation()}>
            <button
              className={styles.closeButton}
              onClick={() => {
                setIsOpen(false);
              }}
            />
            <div className={styles.promoWrapper}>
              <img className={styles.promoLogo} src={dataCreatorHub.modalWindowSection.logoImage} alt="logoImage" />
              <div className={styles.promoTitleWrapper}>
                <h3 className={styles.promoTitle}>{dataCreatorHub.modalWindowSection.startPromoTitle}</h3>{" "}
                <h3 className={styles.promoTitleMarked}>{dataCreatorHub.modalWindowSection.markedPromoTitle}</h3>{" "}
                <h3 className={styles.promoTitle}>{dataCreatorHub.modalWindowSection.endPromoTitle}</h3>
              </div>
              <img className={styles.promoImage} src={dataCreatorHub.modalWindowSection.image} alt="goChampHeroImage" />
            </div>
            <CreatorHubForm />
          </div>
        </div>
      )}
      {isFooter ? (
        <FooterButton text={text} onClick={() => setIsOpen(true)} />
      ) : (
        <DarkButton
          text={text}
          onClick={() => setIsOpen(true)}
          isHeaderAdaptation={isHeader ? styles.headerButton : ""}
          isLandingAdaptation={isLanding ? styles.landingButton : ""}
        />
      )}
    </>
  );
};
