import { useEffect, useState } from "react";

export default function useHeaderVisibility(threshold) {
  const [visible, setVisible] = useState(true);
  let lastScrollY = 0;

  useEffect(() => {
    function handleScroll() {
      const currentScroll = window.pageYOffset;
      const isScrollingDown = lastScrollY > currentScroll;
      lastScrollY = currentScroll <= 0 ? 0 : currentScroll; //prevent negative scroll on mobile

      if (currentScroll > threshold) {
        setVisible(isScrollingDown);
        return;
      }
      setVisible(true);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return visible;
}
