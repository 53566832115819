import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      };

      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};
