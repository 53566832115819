import classes from "./index.module.scss";

export default function FooterForm() {
  return (
    <form
      accept-charset="UTF-8"
      action="https://jvz601.infusionsoft.com/app/form/process/211ecfbefb5655d38fc43da3652c2341"
      className={`${classes.form} infusion-form`}
      id="inf_form_211ecfbefb5655d38fc43da3652c2341"
      method="POST"
    >
      <input
        name="inf_form_xid"
        type="hidden"
        value="211ecfbefb5655d38fc43da3652c2341"
      />
      <input name="inf_form_name" type="hidden" value="Web Form submitted" />
      <input name="infusionsoft_version" type="hidden" value="1.70.0.642516" />
      <div class="infusion-field">
        <input
          id="inf_field_Email"
          name="inf_field_Email"
          label="Email"
          type="email"
          required
          placeholder="Email"
          className={classes.form__input}
        />
      </div>
      <div class="infusion-submit">
        <button
          type="submit"
          className={`${classes.form__button}`}
        >
          Subscribe
        </button>
      </div>
    </form>
  );
}
