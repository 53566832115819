import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { apiURL, pagesIds } from "../constants/api";
import { GlobalContext } from "../context/global/globalContext";

export const useSeo = () => {
  const { pathname } = useLocation();
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const { pages, setPages } = useContext(GlobalContext);
  const fields =
    "_fields=acf,featured_media,img_url,title,yoast_head,slug,content,_links,custom_fields,id&_embed=wp%3Aterm";

  useEffect(() => {
    (pathname || pathname === "") &&
      fetchSeo({ pathname, setPage, setLoading, setPages, pages, fields });
  }, [pathname]);
  return { page, loading };
};

async function fetchSeo({
  pathname = "",
  setPage,
  setLoading,
  setPages,
  pages,
  fields,
}) {
  let request = "";

  const pathArray = pathname.split("/").filter((item) => item);
  const slug = pathArray[pathArray.length - 1];
  const postType = pathArray[0];

  const existingPage = pages.find((page) =>
    slug ? page?.slug === slug : page?.id === pagesIds.home
  );

  if (existingPage) {
    setPage(existingPage);
    setLoading(false);
    return;
  }

  if (!pathArray.length) request = `pages/${pagesIds.home}?${fields}`;
  if (pathArray.length === 2) request = `${postType}?slug=${slug}&${fields}`;
  if (pathArray.length === 1) request = `pages?slug=${slug}&${fields}`;
  if (pathArray[0] === "sift" || pathArray[0] === "orchestrator")
    request = `solution?slug=${slug}&${fields}`;

  try {
    const res = await fetch(`${apiURL}/${request}`);
    const data = await res.json();
    const dataToObject = Array.isArray(data) ? data[0] : data;

    await setPage(dataToObject);
    await setPages([...pages, dataToObject]);
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
}
