import { Link, useLocation } from "react-router-dom";
import classes from "./index.module.scss";
import Container from "../commons/Container";
import ListTitle from "./ListTitle";
import Sci from "./Sci";
import { data } from "./data";
import ListWrapper from "./ListWrapper";
import LinksList from "./LinksList";
import buttonClasses from "../commons/Button.module.scss";
import FooterForm from "../../redesign2022/mailchimp/MailchimpForms/FooterForm";
import { ModalWindow } from "../../creatorHub/components/ModalWindow";

export default function Footer({ menu }) {
  const year = new Date().getFullYear();
  const location = useLocation();
  const { services, solutions } = menu;

  return (
    <footer className={classes.footer}>
      <section className={classes.top}>
        <Container>
          <div className={classes.top__body}>
            <div className={classes.left}>
              <h2 className={classes.title}>{data.title}</h2>
              <FooterForm />
              <Sci data={data.sci} className={classes.sci} />
            </div>
            <div className={classes.right}>
              <div className={classes.menu}>
                <ListWrapper>
                  <ListTitle title={services.name} />
                  <LinksList list={services.items} />
                </ListWrapper>

                <ListWrapper>
                  <ListTitle title={solutions.name} />
                  <LinksList list={solutions.items} />
                </ListWrapper>
              </div>
              <div className={classes.contacts}>
                <ListWrapper>
                  <ListTitle title="Address" />
                  {data.address.map((item) => (
                    <p key={item} className={classes.contact}>
                      {item}
                    </p>
                  ))}
                </ListWrapper>
                <ListWrapper>
                  <ListTitle title="Email" />
                  <a
                    className={classes.contact}
                    href={`mailto:${data.support.href}`}
                  >
                    {data.support.href}
                  </a>
                </ListWrapper>
                <ListWrapper>
                  <ListTitle title="Phone" />
                  <a href={`tel: ${data.phone}`} className={classes.contact}>
                    {data.phone}
                  </a>
                </ListWrapper>
              </div>
              {location.pathname === "/services/creator-hub" ? (
                <ModalWindow text="get in touch" isFooter={true} />
              ) : (
                <Link
                  to={data.btn.link}
                  data-content={data.btn.text}
                  className={`${buttonClasses.button} ${classes.button}`}
                >
                  {data.btn.text}
                </Link>
              )}
            </div>
          </div>
        </Container>
      </section>
      <section className={classes.bottom}>
        <Container>
          <div className={classes.bottom__body}>
            <Link to="/" className={classes.logo}>
              {data.logo}
            </Link>
            <Link to={data.policy.href} className={classes.policy}>
              {data.policy.text}
            </Link>
            <p className={classes.copyright}>
              {`${year} Engenious Inc. (c) All rights reserved`}
            </p>
          </div>
        </Container>
      </section>
    </footer>
  );
}
