import React, { useEffect, useState } from "react";
import { Logo } from "../Logo";
import "./index.css";
import Navigation from "../Navigation";
import UniversityLink from "../../pages/University/UniversityLink";
import NewMenu from "../../redesign2022/components/Menu";
import Menu from "../Menu";
import { useLocation } from "react-router-dom";

const Header = ({ menu }) => {
  const [white, setWhite] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => setWhite(window.pageYOffset > 1));
    }
  }, []);

  return (
    <header className={`topLine row jsb ${white ? "active" : ""}`}>
      <Logo />
      <UniversityLink />
      <Navigation menu={menu} />
      {location?.pathname.split("/")[2] === "native-mobile-automation" ? (
        <NewMenu />
      ) : (
        <Menu />
      )}
    </header>
  );
};

export default Header;
