import Router from "./router";
import "./common.css";
import "./animations.css";
import "./critical.css";
import "./scss/index.scss";
import Layout from "./components/Layout/Layout";
import { useEffect } from "react";

const App = () => {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };

  useEffect(() => {
    window.addEventListener("resize", appHeight);
    appHeight();

    return () => {
      window.removeEventListener("resize", appHeight);
      appHeight();
    };
  }, []);
  return (
    <div className="site-wrapper">
      <Layout>
        <Router />
      </Layout>
    </div>
  );
};

export default App;
