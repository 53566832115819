import React from "react";
import { Logo } from "../../components/Logo";
import { ModalWindow } from "../../components/ModalWindow";
import styles from "./index.module.scss";

const DarkHeader = () => {
  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <Logo />
        <ModalWindow text="GET STARTED" isHeader />
      </header>
    </div>
  );
};

export default DarkHeader;
