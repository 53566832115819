import { HelmetComponent } from "../Helmet";
import NewHeader from "../../redesign2022/layots/Header";
import Header from "../../components/Header";
import Footer from "../Footer";
import { useSeo } from "../../customHooks/useSeo";
import { menuIds } from "../../constants/api";
import { findItemById } from "../../helpers/findItemById";
import classes from "./index.module.scss";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/global/globalContext";
import { useLocation } from "react-router-dom";
import { PopupContext } from "../../context/PopupContext";
import { useScrollToTop } from "../../customHooks/useScrollToTop";
import { PopupModal } from "react-calendly";
import smoothscroll from "smoothscroll-polyfill";
import { chatExceptions } from "../../constants/exceptions";
import ChildrenWithProps from "./ChildrenWithProps";
import { useIsNewPost } from "../../customHooks/useIsNewPost";
import { LiveChatWidget } from "@livechat/widget-react";
import LiveChatButton from "./LiveChatButton";
import DarkHeader from "../../creatorHub/layout/Header";

export default function Layout({ children }) {
  const { getMenu, menu, offices, getOffices } = useContext(GlobalContext);
  const { isPopupOpened, closePopup } = useContext(PopupContext);
  const location = useLocation();
  const { page, loading } = useSeo();
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isChatButtonVisible, setIsChatButtonVisible] = useState(false);
  function onVisibilityChanged(data) {
    switch (data.visibility) {
      case "maximized":
        setIsChatVisible(true);
        break;
      case "minimized":
        setIsChatVisible(false);
        break;
      case "hidden":
        setIsChatVisible(false);
        break;
      default:
        setIsChatVisible(false);
    }
  }

  useScrollToTop();

  useEffect(() => {
    smoothscroll.polyfill();

    !menu && getMenu();
    !offices && getOffices();
  }, []);

  useEffect(() => {
    chatExceptions.includes(location?.pathname.toLowerCase().split("/")[1])
      ? setIsChatButtonVisible(false)
      : setIsChatButtonVisible(true);
  }, [location, window?.LiveChatWidget]);

  useEffect(() => {
    if (isPopupOpened?.calendly !== undefined) {
      document.body.classList.toggle(
        "overflow-hidden",
        isPopupOpened?.calendly
      );
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isPopupOpened?.calendly]);

  return (
    <>
      {page && <HelmetComponent seo={page.yoast_head} />}
      {useIsNewPost() ||
      location?.pathname.split("/")[2] === "native-mobile-automation" ||
      location?.pathname.split("/")[2] === "custom-mobile-app-development" ||
      location?.pathname.split("/")[1] === "sift" ||
      location?.pathname.split("/")[1] === "sift-ios-local-set-up" ||
      location?.pathname.split("/")[1] === "sift-android-local-set-up" ? (
        <NewHeader
          menu={{
            services: findItemById(menu, menuIds.HEADER_SERVICES).items,
            resources: findItemById(menu, menuIds.HEADER_RESOURCES).items,
            company: findItemById(menu, menuIds.HEADER_COMPANY).items,
          }}
        />
      ) : location?.pathname.split("/")[2] === "creator-hub" ? (
        <DarkHeader />
      ) : (
        <Header
          menu={{
            services: findItemById(menu, menuIds.HEADER_SERVICES).items,
            resources: findItemById(menu, menuIds.HEADER_RESOURCES).items,
            company: findItemById(menu, menuIds.HEADER_COMPANY).items,
          }}
        />
      )}

      <main className={`${classes.main}`}>
        <ChildrenWithProps props={{ page, loading }}>
          {children}
        </ChildrenWithProps>
      </main>

      <Footer
        menu={{
          services: findItemById(menu, menuIds.SERVICES),
          company: findItemById(menu, menuIds.ABOUT_THE_COMPANY),
          solutions: findItemById(menu, menuIds.SOLUTIONS),
        }}
        offices={offices}
      />

      {location?.pathname !== "/" && (
        <PopupModal
          url="https://calendly.com/engenious/demo"
          open={isPopupOpened?.calendly}
          rootElement={document.getElementById("engenious")}
          onModalClose={() => closePopup("calendly")}
        />
      )}
      {window.LiveChatWidget && isChatButtonVisible && !isChatVisible ? (
        <LiveChatButton />
      ) : null}
      <LiveChatWidget
        license={process.env.REACT_APP_LIVECHAT_CLIENT_ID}
        visibility="hidden"
        onVisibilityChanged={onVisibilityChanged}
      />
    </>
  );
}
