import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import { Loader } from "./components/Loader";
import { useIsNewPost } from "./customHooks/useIsNewPost";
const SIFT = lazy(() => import("./redesign2022/pages/Solutions/SIFT"));
const Home = lazy(() => import("./pages/Home"));
const VCard = lazy(() => import("./pages/VCard"));
const Services = lazy(() => import("./pages/Services"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Post = lazy(() => import("./pages/Posts/post"));
const RedesignedPost = lazy(() => import("./pages/Posts/redesignedPost"));
const Posts = lazy(() => import("./pages/Posts"));
const Solutions = lazy(() => import("./pages/Solutions"));
const Subscribe = lazy(() => import("./pages/Subscribe"));
const Cases = lazy(() => import("./pages/Cases"));
const Events = lazy(() => import("./pages/Events"));
const Event = lazy(() => import("./pages/Events/Event"));
const Vacancies = lazy(() => import("./pages/Vacancies"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Cartinuity = lazy(() => import("./components/landings/Cartinuity"));
// const A2B = lazy(() => import("./components/landings/A2B"));
const Invet = lazy(() => import("./components/landings/Invet"));
const Page404 = lazy(() => import("./pages/Page404"));
const Grammarly = lazy(() => import("./components/landings/Grammarly"));
const Shapely = lazy(() => import("./components/landings/Shapely"));
const Nextres = lazy(() => import("./components/landings/Nextres"));
// const University = lazy(() => import("./pages/University"));
const Pango = lazy(() => import("./components/landings/Pango"));
const Emerge = lazy(() => import("./components/landings/Emerge"));
const WeedAR = lazy(() => import("./components/landings/WeedAR"));
const Vacancy = lazy(() => import("./components/Vacancy"));
const SiftIosSetup = lazy(() => import("./pages/SIFTSetup/IOS"));
const SiftAndroidSetup = lazy(() => import("./pages/SIFTSetup/Android"));

// const IosDevelopment = lazy(() => import("./pages/Service/IosDevelopment"));
// const AndroidDevelopment = lazy(() =>
//   import("./pages/Service/AndroidDevelopment")
// );
const ArVr = lazy(() => import("./pages/Service/ArVr"));
const Ai = lazy(() => import("./redesign2022/pages/Services/Ai"));
const Accessibility = lazy(() => import("./pages/Service/Accessibility"));
const WebDevelopment = lazy(() => import("./pages/Service/WebDevelopment"));

const CICD = lazy(() => import("./pages/Service/CICD"));
const NativeMobileAutomation = lazy(() =>
  import("./redesign2022/pages/Services/NativeMobileAutomation")
);
const CustomMobileAppDevelopment = lazy(() =>
  import("./redesign2022/pages/Services/CustomMobileAppDevelopment")
);
const CreatorHub = lazy(() => import("./creatorHub/pages/Services/CreatorHub"));

const Router = (props) => {
  const renderLoader = () => <Loader />;
  return (
    <Suspense fallback={renderLoader()}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sift" element={<SIFT props={props} />} />
        <Route
          path="/sift-ios-local-set-up"
          element={<SiftIosSetup props={props} />}
        />
        <Route
          path="/sift-android-local-set-up"
          element={<SiftAndroidSetup props={props} />}
        />
        <Route path="/services" element={<Services />} />
        <Route path="/events" element={<Events />} />
        <Route path="/events/:slug" element={<Event />} />

        {/* <Route
          path="/services/android-development"
          element={<AndroidDevelopment />}
        /> */}
        {/* <Route path="/services/ios-development" element={<IosDevelopment />} /> */}
        <Route path="/services/ar-vr" element={<ArVr />} />
        <Route path="/services/ai-development" element={<Ai />} />
        <Route path="/services/accessibility" element={<Accessibility />} />
        <Route path="/services/web-developments" element={<WebDevelopment />} />
        <Route
          path="/services/native-mobile-automation"
          element={<NativeMobileAutomation props={props} />}
        />
        <Route
          path="/services/custom-mobile-app-development"
          element={<CustomMobileAppDevelopment props={props} />}
        />

        <Route path="/services/cicd" element={<CICD />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/posts/:slug" element={<OldOrNewPost />} />
        <Route path="/contact-us" element={<ContactUs props={props} />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/about-us/:slug" element={<VCard />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/solutions" element={<Solutions props={props} />} />
        <Route path="/cases" element={<Cases />} />
        <Route path="/cases/cartinuity" element={<Cartinuity />} />
        <Route path="/cases/grammarly" element={<Grammarly />} />
        <Route path="/cases/nextres" element={<Nextres />} />
        {/* <Route path="/university" element={<University />} /> */}
        <Route path="/cases/weedar" element={<WeedAR />} />
        <Route path="/cases/pango" element={<Pango />} />
        <Route path="/cases/emerge" element={<Emerge />} />
        {/* <Route path="/cases/a2b" element={<A2B />} /> */}
        <Route path="/cases/invet" element={<Invet />} />
        <Route path="/vacancies" element={<Vacancies />} />
        <Route path="/vacancies/:slug" element={<Vacancy />} />
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy props={props} />}
        />
        <Route path="/cases/shapely" element={<Shapely />} />
        <Route path="*" element={<Page404 />} />
        <Route path="/services/creator-hub" element={<CreatorHub />} />
      </Routes>
    </Suspense>
  );
};

function OldOrNewPost() {
  const isNewPost = useIsNewPost();

  if (isNewPost !== null) {
    return isNewPost ? <RedesignedPost /> : <Post />;
  }
}

export default Router;
