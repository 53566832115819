import React, { useState, useEffect, useRef } from "react";
import styles from "./index.module.scss";

export const TextareaOutline = ({ id, name, label, errorValue, maxLength, ...props }) => {
  const textareaRef = useRef(null);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    const textarea = textareaRef.current;
    const updateCharCount = () => {
      if (textarea) {
        setCharCount(textarea.value.length);
      }
    };
    textarea.addEventListener("input", updateCharCount);
    return () => {
      textarea.removeEventListener("input", updateCharCount);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{label}</span>
      <textarea
        ref={textareaRef}
        id={id}
        name={name}
        rows={4}
        {...props}
        style={{
          border: errorValue ? "2px solid rgba(255, 61, 46, 0.2)" : null,
        }}
        className={styles.textarea}
      />

      {charCount < 501 && (
        <div className={styles.valueBlock}>
          <p className={styles.errorText}>{errorValue}</p>
          <p>{charCount}/500</p>
        </div>
      )}
      {charCount > 500 && (
        <div className={styles.errorBlock}>
          <p className={styles.errorText}>Message exceeds maximum character limit</p>
          <p>{charCount}/500</p>
        </div>
      )}
    </div>
  );
};
