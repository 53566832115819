import { Link } from "react-router-dom";
import "./index.css";
import { ReactComponent as EngeniousLogo } from "./logo.svg";

export const Logo = () => {
  return (
    <Link
      to={{
        pathname: "/",
      }}
      className={`logo`}
    >
      <EngeniousLogo />
    </Link>
  );
};
