import React from "react";
import styles from "./index.module.scss";

const FooterButton = ({ text, onClick }) => {
  return (
    <>
      <button data-content={text} className={styles.button} onClick={onClick}>
        {text}
      </button>
    </>
  );
};

export default FooterButton;
