import React from "react";
import styles from "./index.module.scss";

const DarkButton = ({
  text,
  onClick,
  isHeaderAdaptation,
  isLandingAdaptation,
}) => {
  const btnClass = `${styles.btn} ${isHeaderAdaptation} ${isLandingAdaptation}`;

  return (
    <button className={btnClass} onClick={onClick}>
      {text}
    </button>
  );
};

export default DarkButton;
