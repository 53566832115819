import classes from "./index.module.scss";
import { renderMenu } from "./renderMenu";
import { useContext } from "react";
import { PopupContext } from "../../../context/PopupContext";

export default function Navigation({ menu }) {
  const isMenu = (menu) => !!Object.values(menu).filter(Boolean).length;

  const { openPopup } = useContext(PopupContext);
  const openCalendly = () => openPopup("calendly");

  if (!isMenu(menu)) {
    return null;
  }
  return <nav className={classes.nav}>{renderMenu(menu, openCalendly)}</nav>;
}
