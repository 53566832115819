import classes from "./index.module.scss";
import { v4 as uuid } from "uuid";
import Dropdown from "./Dropdown";
import BtnClasses from "../../modules/Button.module.scss";

export const renderMenu = (menu, openCalendly) => {
  const list = [];
  for (const key in menu) {
    if (menu.hasOwnProperty(key)) {
      menu[key] &&
        list.push(
          <li key={uuid()} className={classes.list__item}>
            <Dropdown list={menu[key]} title={key} />
          </li>
        );
    }
  }

  return (
    <div className={classes.wrapper}>
      <ul className={classes.list}>{list}</ul>
      <button
        onClick={openCalendly}
        className={`${BtnClasses.button} ${classes.requestButton}`}
      >
        Request A FREE EVALUATION
      </button>
    </div>
  );
};
