import { ReactComponent as Icon } from "./chat.svg";
import classes from "./index.module.scss";

export default function LiveChatButton() {
  const handleClick = () => {
    window.LiveChatWidget.call("maximize");
  };

  return (
    <>
      <button
        onClick={handleClick}
        className={`${classes.button} ${classes.button__desktop}`}
      >
        <Icon />Live chat
      </button>
      <button
        onClick={handleClick}
        className={`${classes.button} ${classes.button__mobile}`}
      >
        <Icon />
      </button>
    </>
  );
}
