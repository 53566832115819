import { Link } from "react-router-dom";
import { ReactComponent as EngeniousLogo } from "./logo.svg";
import styles from "./index.module.scss";

export const Logo = () => {
  return (
    <Link
      to={{
        pathname: "/",
      }}
      className={styles.logo}
    >
      <EngeniousLogo />
    </Link>
  );
};
