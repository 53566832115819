import React, { useState } from "react";
import { contactFormURL } from "../../constants/api";
import jsonp from "jsonp";
import { FormContext } from "./formContext";

const FormState = ({ children }) => {
  const [notif, setNotification] = useState({});
  const [notifCV, setNotifCV] = useState(null);
  const [pdfCV, setPdfCV] = useState(null);
  const [isLoadigCV, setIsloadingCV] = useState(false);

  const sendContactForm = (data, id) => {
    const formData = new FormData();

    for (var name in data) {
      formData.append(name, data[name]);
    }

    fetch(`${contactFormURL}/${id}/feedback`, {
      method: "POST",
      body: formData,
    })
      .then((data) => data.json())
      .then((data) => {
        data?.data?.status
          ? setNotification({
              status: "error",
              message: "Something went wrong. Please try again later.",
            })
          : setNotification(data);
      });
  };

  const sendCV = (data, id) => {
    const formData = new FormData();
    formData.append("file_CV", data.cv);
    formData.append("subject", data.subject);

    setIsloadingCV(true);
    fetch(`${contactFormURL}/${id}/feedback`, {
      method: "POST",
      body: formData,
    })
      .then((data) => data.json())
      .then((data) => {
        setIsloadingCV(false);
        setNotifCV(data);
      });
  };

  const mailChimpSubscribe = (url, email, fname, lname) => {
    jsonp(
      `${url}&EMAIL=${email}&FNAME=${fname}&LNAME=${lname}`,
      { param: "c" },
      (_, data) => {
        if (data.result) {
          setNotification({
            status: data.result !== "success" ? "error" : "success",
            message: data.msg,
          });
        }
      }
    );
  };

  return (
    <FormContext.Provider
      value={{
        mailChimpSubscribe,
        sendContactForm,
        notif,
        setNotification,
        setPdfCV,
        pdfCV,
        sendCV,
        notifCV,
        setNotifCV,
        isLoadigCV,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormState;
