import { v4 as uuid } from "uuid";
import classes from "./index.module.scss";

export default function Sci({ data, className = "" }) {
  return (
    <ul className={`${classes.sci} ${className}`}>
      {data.map(({ href, icon }) => {
        return (
          <li key={uuid()} className={classes.sci__item}>
            <a href={href} rel="noreferrer" target="_blank">
              {icon}
            </a>
          </li>
        );
      })}
    </ul>
  );
}
