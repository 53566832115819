import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./index.module.scss";

export const PhoneInputOutline = ({ id, label, name, required, errorValue }) => {
  return (
    <div>
      <PhoneInput
        country={"us"}
        countryCodeEditable={false}
        copyNumbersOnly={false}
        containerClass={styles.phone}
        specialLabel={label}
        inputProps={{
          id: id,
          name: name,
        }}
        isValid={!errorValue}
      />
      {errorValue && <p className={styles.errorText}>{errorValue}</p>}
    </div>
  );
};
