export const apiURL = process.env.REACT_APP_API_URL + "/wp-json/wp/v2";
export const contactFormURL =
  "https://api.engenious.io/wp-json/contact-form-7/v1/contact-forms";

export const youtubeSubscribeUrl =
  "https://engenious.us12.list-manage.com/subscribe/post-json?u=45d28f304740b7e895a0adcae&amp;id=b0bf909d89";

export const stagingUrl =
  "https://gmail.us4.list-manage.com/subscribe/post-json?u=9f15a7ecba50b523127632e6c&amp;id=e7532deaee";
export const menuUrl =
  process.env.REACT_APP_API_URL + "/wp-json/wp-api-menus/v2/menus";

export const footerMailchimpLink =
  "https://engenious.us12.list-manage.com/subscribe/post?u=45d28f304740b7e895a0adcae&id=90d17a7547&f_id=00daa8e0f0";

export const menuIds = {
  ABOUT_THE_COMPANY: 49,
  SERVICES: 51,
  SOLUTIONS: 52,
  MAIN_MENU: 53,
  HEADER_MENU: 54,
  HEADER_RESOURCES: 107,
  HEADER_SERVICES: 106,
  HEADER_COMPANY: 108,
};

export const pagesIds = {
  home: 34,
  contactUs: 15,
  termsOfUse: 40,
  privacyPolicy: 38,
  solutions: 140,
  policy: 504,
  aboutUs: 778,
  cases: 774,
  events: 1404,
  posts: 13,
  services: 772,
  vacancies: 776,
};

export const contactFormsIds = {
  contactUs: 46,
  sendCV: 254,
  university: 1180,
};
