import { ReactComponent as EngeniousLogo } from "./images/logo.svg";
import { ReactComponent as Fb } from "./images/facebook.svg";
import { ReactComponent as Ln } from "./images/linkedin.svg";
import { ReactComponent as Yt } from "./images/youtube.svg";
import { ReactComponent as In } from "./images/instagram.svg";

const logo = <EngeniousLogo />;

export const data = {
  title: `Get weekly test automation tips to your inbox`,
  logo,
  btn: {
    link: "/contact-us",
    text: "Get in touch",
  },
  policy: {
    href: "/privacy-policy",
    text: "Privacy Policy",
  },

  address: [
    `Engenious, Inc`,
    `7901 4th St. N STE 15096`,
    `St. Petersburg, FL 33702 US`,
  ],
  phone: [`+1 (305) 522-5212`],
  support: {
    href: [`info@engenious.io`],
  },
  sci: [
    {
      href: "https://www.linkedin.com/company/engenious-consulting/",
      icon: <Ln />,
    },
    {
      href: "https://www.facebook.com/engenious.io/",
      icon: <Fb />,
    },
    {
      href: "https://www.instagram.com/engenious.io/",
      icon: <In />,
    },
    {
      href: "https://www.youtube.com/engenious",
      icon: <Yt />,
    },
  ],
};
