import React from "react";

export default function ChildrenWithProps({ children, props }) {
  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(
          child,
          {
            ...props,
          },
          null
        )
      )}
    </>
  );
}
