import hero from "../sections/Hero/images/goChampHero.png";
import languagesScreen from "../sections/Carousel/images/languagesScreen.png";
import itScreen from "../sections/Carousel/images/itScreen.png";
import fitnessScreen from "../sections/Carousel/images/fitnessScreen.png";
import yogaScreen from "../sections/Carousel/images/yogaScreen.png";
import musicScreen from "../sections/Carousel/images/musicScreen.png";
import pokerScreen from "../sections/Carousel/images/pokerScreen.png";
import mindsetScreen from "../sections/Carousel/images/mindsetScreen.png";
import danceScreen from "../sections/Carousel/images/danceScreen.png";
import howItWorks1 from "../sections/HowItWorks/images/howItWorks1.png";
import howItWorks2 from "../sections/HowItWorks/images/howItWorks2.png";
import howItWorks3 from "../sections/HowItWorks/images/howItWorks3.png";
import solution from "../sections/Solution/images/solution.png";
import offer1 from "../sections/Offer/images/offer1.png";
import offer2 from "../sections/Offer/images/offer2.png";
import offer3 from "../sections/Offer/images/offer3.png";
import offer4 from "../sections/Offer/images/offer4.png";
import logo from "../components/Logo/logo.svg";
import demoScreen from "../sections/Demo/images/demoimg.png";
import iosBtn from "../sections/Demo/images/IOSbtn.svg";
import googleBtn from "../sections/Demo/images/googlebtn.svg";

export const dataCreatorHub = {
  hero: {
    title: "Creator Hub by Engenious",
    text: "Earn more from your content with",
    markedText: "Your own app in just 30* days",
    description:
      "You focus on creating great content. Let us worry about building your dream app.",
    image: hero,
  },
  contentSection: {
    text: "Make your",
    markedText: "Content works harder",
    description:
      "We build you a natively developed app for Android and iOS that allows full customization of features to match your brand and your style. It’s your brand customization dream – and we can help you make it.",
    framesSection: [
      languagesScreen,
      itScreen,
      fitnessScreen,
      yogaScreen,
      musicScreen,
      pokerScreen,
      mindsetScreen,
      danceScreen,
      languagesScreen,
      itScreen,
      fitnessScreen,
      yogaScreen,
      musicScreen,
      pokerScreen,
      mindsetScreen,
      danceScreen,
      languagesScreen,
      itScreen,
      fitnessScreen,
      yogaScreen,
      musicScreen,
      pokerScreen,
      mindsetScreen,
      danceScreen,
    ],
  },
  hubSection: {
    title: "launch your apps",
    markedTitle: "10x cheaper",
    subTitle: "and start selling",
    markedSubTitle: "12x faster",
    description:
      "Creator Hub by Engenious specializes in white label app development with extreme efficiency and customizable features built-in. That means that we can do what other traditional app developers can do, but for considerably less cost.",
    cardTitle: "With creator hub",
    cardSubTitle: "30 days, idea to launch starting as low as $20,000.00",
    developersTitle: "Other App developers",
    developingPeriod: "12-18 months at 10x the cost",
  },
  howItWorksSection: {
    title: "how it",
    markedTitle: "works",
    content: [
      {
        image: howItWorks1,
        startTitle: "we build you 100% customizable",
        markedTitle: "iOS and Android apps",
        endTitle: "from idea to launch",
        text: "We set up an initial meeting with you to discuss everything you want in an app. We go over all aspects of the app, including the design elements, user engagement tools, and much more. Then you let us take the wheel to make it all happen!",
      },
      {
        image: howItWorks2,
        startTitle: "you continue to craft content while",
        markedTitle: "we maintain things",
        endTitle: "in the background",
        text: "After the launch of your app, we manage the servers, maintenance, updates, bug fixes, etc. so you can fully focus on creating your content. However, we want you to always be in the know about your content’s performance. Creator Hub provides tracking and analytics tools as well as metrics to gauge the success of your content with your audience.",
      },
      {
        image: howItWorks3,
        startTitle: "generate",
        markedTitle: "more revenue",
        endTitle: "from your content",
        text: "Your content can be working much harder for you! Increase your revenue by adding additional value-driven offerings for your loyal fans such as in-app subscriptions, premium services that provide more exclusive content, and in-app purchases such as merch and other valuables that represent your brand.",
      },
    ],
  },
  solutionSection: {
    markedTitle: "a perfect solution",
    title: "for delivering content, building communities, and creating courses",
    image: solution,
    titleListOne: "who can get benefits from our service",
    listOne: [
      ["Content creators", "Online coaches"],
      ["Video streamers", "Consultants"],
      ["Marketers", "Teachers"],
      ["Online course instructors", "Artists"],
    ],
    titleListTwo: "great for any field",
    listTwo: [
      ["Fitness", "Yoga", "Sports"],
      ["Languages", "Education", "Business"],
      ["Gaming", "Meditation", "Dancing"],
      ["Art", "Music", "And others"],
    ],
  },
  offerSection: {
    title: "what our experts",
    markedTitle: "offer you",
    content: [
      {
        image: offer1,
        startTitle: "ios and android",
        markedTitle: "apps",
        text: "Once we have completed your app builds, we’ll handle the launch and reveal your shiny new app to the world. We ensure that your app has cross-platform compatibility with iOS and Android and is available on both the App Store and Google Play store.",
      },
      {
        image: offer2,
        endTitle: "development",
        markedTitle: "custom features",
        text: "Does your business have a specific feature that you know your audience would love? Our team of developers can help you design and build out custom features and personalized content delivery methods to fully engage your subscribers.",
      },
      {
        image: offer3,
        startTitle: "full access to",
        markedTitle: "admin panel",
        text: "Once your app is live, you are in control! Access content management, analytics, and other useful tools via your custom-built admin panel.",
      },
      {
        image: offer4,
        startTitle: "5-star",
        markedTitle: "customer support",
        text: "If you ever run into an issue with your app’s performance or functionality, we have your back! Our team of dedicated support staff is always available to help you troubleshoot problems and find solutions. Creator Hub also offers ongoing support and updates to your app to keep things running smoothly.",
      },
    ],
  },
  calculatorSection: {
    title: "here’s how much",
    markedTitle: "you can earn",
    minUsers: 0,
    maxUsers: 10000,
    minCharge: 0,
    maxCharge: 100,
  },
  demoSection: {
    markedTitle: "GOChamp",
    title: "Demo",
    description:
      "GoChamp was our first major project, and we couldn’t be happier with the result! We created an app for Ivan Baranchyk, the 2018-2019 IBF Light-Welterweight Champion, and the result was a knock-out success.",
    linkIOS: "https://apps.apple.com/sk/app/gochamp/id6448703105",
    imageLinkIOS: iosBtn,
    linkAndroid:
      "https://play.google.com/store/apps/details?id=io.engenious.gochamp&pli=1",
    imageLinkAndroid: googleBtn,
    image: demoScreen,
  },
  promo: {
    title: "What are you waiting for?",
    subtitle:
      "Start earning more for your content with your custom apps today!",
    description:
      "*30 days is considered an average timeframe for project completion and should not be considered a guarantee by Engenious or its affiliates.",
  },
  modalWindowSection: {
    logoImage: logo,
    image: hero,
    startPromoTitle: "start building",
    markedPromoTitle: "your dream app today",
    endPromoTitle: "with creation hub by engenious",
    formTitle: "creator hub application",
    formTagsLabel: "Your Field",
    formTags: [
      {
        text: "Fitness",
        id: "inf_option_Fitness",
        name: "inf_option_Fitness",
        value: "278",
      },
      {
        text: "Yoga",
        id: "inf_option_Yoga",
        name: "inf_option_Yoga",
        value: "280",
      },
      {
        text: "Sport",
        id: "inf_option_Sport",
        name: "inf_option_Sport",
        value: "282",
      },
      {
        text: "Languages",
        id: "inf_option_Languages",
        name: "inf_option_Languages",
        value: "284",
      },
      {
        text: "Education",
        id: "inf_option_Education",
        name: "inf_option_Education",
        value: "286",
      },
      {
        text: "Business",
        id: "inf_option_Business",
        name: "inf_option_Business",
        value: "288",
      },
      {
        text: "Gaming",
        id: "inf_option_Gaming",
        name: "inf_option_Gaming",
        value: "290",
      },
      {
        text: "Meditation",
        id: "inf_option_Meditation",
        name: "inf_option_Meditation",
        value: "292",
      },
      {
        text: "Dancing",
        id: "inf_option_Dancing",
        name: "inf_option_Dancing",
        value: "294",
      },
      {
        text: "Art",
        id: "inf_option_Art",
        name: "inf_option_Art",
        value: "296",
      },
      {
        text: "Music",
        id: "inf_option_Music",
        name: "inf_option_Music",
        value: "298",
      },
      {
        text: "Other",
        id: "inf_option_Other",
        name: "inf_option_Other",
        value: "300",
      },
    ],
    formPhoneLabel: "Phone",
    formPhoneText: "+1 (305) 522 - 5212",
    formPhone: "13055225212",
    formEmailLabel: "Email",
    formEmail: "creatorhub@engenious.io",
    formSubmitButtonText: "apply now",
    formFooterDescription:
      "Engenious needs the information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time.",
  },
};
